function stepReveal() {
  let steps = document.querySelectorAll('.done-section-step-unreveal');

  for (let i = 0; i < steps.length; i++) {
    let windowHeight = window.innerHeight;
    let elementTop = steps[i].getBoundingClientRect().top;
    let elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      steps[i].classList.add('done-section-step-reveal');
    }
  }
}

window.addEventListener('scroll', stepReveal);
